<template>
  <div class="credCoupon">
    <h1 @click="shouClick">首页<span>></span>PLUS卡券</h1>
    <div class="credCoupon_banner">
      <img src="../../assets/icon_3.png" alt="" />
      <div>立即绑定，邀您共享全新体验</div>
    </div>
    <div class="credCoupon_fooder">
      <div @click="bindClick">
        <p><img src="../../assets/icon_1.png" alt="" /> 绑定卡券</p>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div @click="searchClick">
        <p><img src="../../assets/icon_10.png" alt="" /> 查询卡券</p>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div @click="boundClick" v-if="bandCard != null">
        <p><img src="@/assets/icon_7.png" alt="" /> 已绑定</p>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="means" v-if="QrCodeVisible">
      <h2>{{ title }} <i @click="backClick" class="el-icon-close"></i></h2>
      <div class="means_banner" v-if="meanShow">
        <div v-if="chaxShow">
          <h3><span>*</span> 券号</h3>
          <el-input
            v-model="inpuValuet"
            placeholder="请输入查询卡券号"
          ></el-input>
        </div>
        <div v-if="!chaxShow">
          <h4><span>*</span> 券号</h4>
          <el-input
            v-model="credValuet"
            placeholder="请输入查询卡券号"
          ></el-input>
          <h4><span>*</span> 密码</h4>
          <el-input
            v-model="passwordValuet"
            :type="show_pass ? 'text' : 'password'"
            placeholder="请输入查询卡券号"
          ></el-input>
          <div class="login-content-inpwrap-type" data-type="false">
            <img
              v-if="!show_pass"
              :src="require('assets/images/login-changetype.png')"
              alt=""
              @click="show_pass = true"
            />
            <img
              v-else
              :src="require('assets/images/login-yan-h.png')"
              alt=""
              @click="show_pass = false"
            />
          </div>
        </div>
      </div>
      <div class="means_banner" v-if="!meanShow">
        <ul>
          <li>
            卡券类型：<span>{{ sty.pluscardTypeName }}</span>
          </li>
          <li>
            卡号： <span>{{ sty.cardCode }}</span>
          </li>
          <li>
            有效期： <span>{{ sty.expirationDate }}</span>
          </li>
          <li>
            开通时间： <span>{{ sty.bindingTime }}</span>
          </li>
          <li>
            到期时间 <span>{{ sty.expirationDate }}</span>
          </li>
        </ul>
      </div>
      <div class="means_fooder" v-if="meanShow">
        <div class="confim" @click="confim">确定</div>
        <div class="back" @click="backClick">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getcheckCard, getbindCard, findPlusContextual } from "api/service";
export default {
  data() {
    return {
      QrCodeVisible: false,
      meanShow: true,
      chaxShow: true,
      show_pass: false,
      //   搜索内容
      inpuValuet: "",
      passwordValuet: "",
      credValuet: "",
      title: "卡券查询",
      sty: {},
      bandCard: "",
    };
  },
  created() {
    this.findPlusContextual();
  },
  methods: {
    shouClick() {
      this.$router.push({ name: "shoppingMall" });
    },
    async findPlusContextual() {
      let data = await findPlusContextual();
      this.bandCard = data.data;
    },
    // 取消
    backClick() {
      this.QrCodeVisible = false;
      this.inpuValuet = "";
      this.passwordValuet = "";
      this.credValuet = "";
      this.meanShow = true;
    },
    async confim() {
      if (this.chaxShow) {
        if (!this.inpuValuet) {
          return this.$message({
            type: "error",
            message: "请输入券号！",
          });
        }
        let data = await getcheckCard({ cardCode: this.inpuValuet });
        if (data.code == 10004) {
          this.inpuValuet = "";
          return this.$message({
            type: "error",
            message: data.message,
          });
        } else {
          this.sty = data.data;
          this.meanShow = false;
          this.title = "查询结果";
        }
        this.inpuValuet = "";
      } else {
        if (!this.credValuet) {
          return this.$message({
            type: "error",
            message: "请输入券号！",
          });
        }
        if (!this.passwordValuet) {
          return this.$message({
            type: "error",
            message: "请输入密码！",
          });
        }
        let params = {
          cardCode: this.credValuet,
          cardPassword: this.passwordValuet,
        };
        let data = await getbindCard(params);
        if (data.code == 200) {
          this.$message({
            type: "succses",
            message: data.message,
          });
          this.credValuet = "";
          this.passwordValuet = "";
          this.QrCodeVisible = false;
        } else {
          this.$message({
            type: "error",
            message: data.message,
          });
        }
      }
    },
    // 搜索
    searchClick() {
      this.title = "卡券查询";
      this.chaxShow = true;
      this.QrCodeVisible = true;
    },
    bindClick() {
      this.title = "绑定卡券";
      this.chaxShow = false;
      this.QrCodeVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.credCoupon {
  width: 1200px;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  background-color: #fff;
  .means {
    position: relative;
    width: 428px;
    height: 350px;
    padding: 16px 11px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
    border-radius: 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
    h2 {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #393939;
      .el-icon-close {
        cursor: pointer;
      }
    }
    .means_banner {
      position: relative;
      width: 100%;
      h3 {
        margin: 63px 0 19px 19px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333;
        span {
          color: #eb441e;
        }
      }
      h4 {
        margin: 30px 0 19px 19px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333;
        span {
          color: #eb441e;
        }
      }
      .el-input {
        width: 366px;
        height: 50px;
        margin-left: 19px;
        ::v-deep input {
          width: 100%;
          height: 100%;
          line-height: 50px;
        }
      }
      ul {
        width: 100%;
        padding: 33px 50px 0px 30px;
        box-sizing: border-box;
        li {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 49px;
          span {
            float: right;
          }
        }
      }
    }
    .means_fooder {
      position: absolute;
      left: 0px;
      bottom: 60px;
      width: 100%;
      display: flex;

      .confim {
        cursor: pointer;
        position: absolute;
        right: 115px;
        top: 0;
        width: 72px;
        height: 41px;
        background: #4c8cff;
        border: 1px solid #ff6900;
        border-radius: 3px;
        text-align: center;
        line-height: 41px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .back {
        cursor: pointer;
        position: absolute;
        right: 32px;
        top: 0;
        width: 72px;
        height: 41px;
        background: #ffffff;
        border: 1px solid #cecece;
        border-radius: 3px;
        text-align: center;
        line-height: 41px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        margin-left: 12px;
      }
    }
  }
  h1 {
    font-size: 14px;
    height: 21px;
    line-height: 21px;
    font-family: PingFang SC;
    font-weight: 500;
    margin: 30px 0 0 50px;
    color: #333333;
    border-left: 3px solid #ff6900;
    padding-left: 11px;
    span {
      margin: 0 20px;
    }
  }
  .credCoupon_banner {
    width: 100%;
    text-align: center;
    margin-top: 31px;
    img {
      width: 688px;
      height: 388px;
    }
    div {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 44px;
    }
  }
  .credCoupon_fooder {
    width: 100%;
    margin-top: 43px;
    div {
      display: flex;
      justify-content: space-between;
      width: 414px;
      height: 50px;
      line-height: 32px;
      background: #ffffff;
      box-shadow: 0px 5px 19px 2px rgba(229, 229, 229, 0.38);
      border-radius: 10px;
      margin: 20px auto;
      padding: 9px 14px;
      box-sizing: border-box;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      img {
        width: 32px;
        height: 32px;
        vertical-align: middle;
        margin-right: 18px;
      }
      .el-icon-arrow-right {
        margin-top: 8px;
      }
    }
  }
}
.login-content-inpwrap-type {
  position: absolute;
  bottom: 15px;
  right: 20px;
  margin-right: 18px;
  width: 20px;
  height: 20px;
  img {
    width: 100%;
    height: inherit;
  }
}
</style>
